.delivery {
    padding: 10px 12px;
    .card {
        padding: 10px 10px 32px;
        background-color: #fff;
        border-radius: 10px;
        &-inner {
            background-color: #F1F1F5;
            border-radius: 5px;
            padding: 0 10px 10px;
            margin-bottom: 10px;
        }
        &-title {
            color: #1B1A1E;
            font-size: 16px;
            font-weight: 500;
            line-height: 32px;
        }
        &-input {
            position: relative;
            padding: 12px 0;
            margin-bottom: 12px;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                border-bottom: 1px solid #E9E8EF;
                transform-origin: 0 100%;
                transform: scaleY(0.5);
                pointer-events: none;
            }
            input {
                width: 100%;
                height: 32px;
                color: #1B1A1E;
                font-size: 15px;
                line-height: 32px;
                padding: 0;
                &::-webkit-input-placeholder,
                &::placeholder {
                    color: #8B8B92;
                    font-size: 13px;
                    font-weight: 400;
                }
            }
        }
        &-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 44px;
            border-radius: 44px;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            background: linear-gradient(135deg, #5B3CF9, #9334EA, #F13DB7);
            margin-top: 44px;
            &.disabled {
                opacity: 0.5;
            }
        }
        &-saoyisao {
            width: 67px;
            height: 32px;
            border-radius: 32px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            gap: 3px;
            color: #1B1A1E;
            font-size: 13px;
            border: 1px solid rgba(0,0,0,0.2);
        }
        .item-title {
            position: relative;
            height: 30px;
            line-height: 30px;
            color: #8B8B92;
            font-size: 12px;
            margin-bottom: 10px;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                border-bottom: 1px solid #E9E8EF;
                transform-origin: 0 100%;
                transform: scaleY(0.5);
                pointer-events: none;
            }
        }
        .item-good {
            padding-bottom: 10px;
            &__media {
                width: 74px;
                height: 74px;
                margin-right: 10px;
            }
            &__img {
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 5px;
            }
            &__title {
                color: #1B1A1E;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                margin-bottom: 3px;
            }
            &__subtl {
                color: #8B8B92;
                font-size: 12px;
                line-height: 17px;
                font-weight: 400;
            }
            &__after {
                min-width: 55px;
                margin-left: 10px;
            }
            &__amount {
                color: #1B1A1E;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 3px;
                white-space: nowrap;
            }
            &__number {
                color: #8B8B92;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 3px;
            }
        }
        .item-total {
            color: #1B1A1E;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            padding-bottom: 10px;
            .b {
                margin-left: 3px;
                font-weight: 500;
            }
        }
        .item-cells {
            position: relative;
            padding: 10px 0;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                border-top: 1px solid #E9E8EF;
                transform-origin: 0 0;
                transform: scaleY(0.5);
                pointer-events: none;
            }
        }
        .item-cell {
            color: #1B1A1E;
            font-size: 14px;
            line-height: 20px;
            &__label {
                color: #63626C;
                font-size: 13px;
                padding-right: 10px;
            }
            +.item-cell {
                margin-top: 10px;
            }
        }
    }
}